<template>
  <div
    class="column is-12"
  >
    <VueSignaturePad
      :id="field.id"
      ref="signatureElement"
      :options="{ onBegin, onEnd }"
      width="340px"
      height="100px"
      class="signature"
    />
    <b-field
      class="signature-field"
      style="margin-left: -10px; max-width: 340px;"
    >
      <b-button
        size="is-small"
        type="is-dark is-light is-outlined"
        expanded
        class="sign-btn"
        @click="clearSignature(field.id)"
      >
        <b-icon
          size="is-small"
          icon="cancel"
        />
        <span>Löschen</span>
      </b-button>
    </b-field>
  </div>
</template>

<script >
import { onMounted, ref } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const signatureElement = ref(null);
  const loadSignature = () => {
    signatureElement.value.fromDataURL(props.field.value);
  };
  onMounted(() => {
    loadSignature();
  });
  const onBegin = () => {
    // do nothing
  };
  const onEnd = () => {
    const png = signatureElement.value.saveSignature();
    emit('update', {
      value: png
    });
  };
  const clearSignature = () => {
    signatureElement.value.clearSignature();
    emit('update', {
      value: {
        data: ''
      }
    });
  };
  return {
    signatureElement,
    onBegin,
    onEnd,
    clearSignature
  };
};
export default __sfc_main;
</script>

<style lang="scss">
.signature {
  border: 1px solid #dedede;
  margin-left: -10px;
}
</style>
